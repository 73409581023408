import React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Social from '../components/Social';
import Movie from '../components/utils/Movie';
import ContactText from '../components/otherlanguage/ContactText';
import IntroductionHeader from '../components/header/IntroductionHeader';
import '../assets/sass/_masthead.scss';

const introduction = () => {
  
  return(
    <Layout>
      <IntroductionHeader />
      <header className="masthead">
        <div className="container my-auto">
          <div className="howtouse video_wrapper" > 
            <Movie/>
          </div>
        </div>
      </header>

      <section className="contact bg-primary" id="contact">
        <div className="container">
          <h2>
            <ContactText/>
          </h2>
          <Social />
        </div>
      </section>
      <Footer />
    </Layout>
  )
};

export default introduction;
