import React from 'react';

export default function Movie(param) {
  return (
    <div className="youtube">
      <iframe
        id='YoutubeUrl'
        src="https://www.youtube.com/embed/MtCzVlcQVcU"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
    </div>
  );
}
